import { DetailPresentationStyle } from '@/components/InventoryBrowser/hooks/useDetailPresentationStyle'
import { ContentScalingFunction } from './grid'

const ratio = (parent: number, child: number = 0): number =>
  (parent - child) / parent

export const rightColumnGridContentScalingFunction =
  (
    detailPresentationStyle: DetailPresentationStyle,
    rightColumnWidthWhenVisible: number,
    showingRightColumn: boolean
  ): ContentScalingFunction =>
  (containerWidth, mode) => {
    const scaleWhenRightColumnIsOpen = ratio(
      containerWidth,
      detailPresentationStyle === 'inline'
        ? rightColumnWidthWhenVisible
        : undefined
    )

    switch (mode) {
      case 'current':
        return showingRightColumn ? scaleWhenRightColumnIsOpen : 1
      case 'minimum':
        return scaleWhenRightColumnIsOpen
    }
  }

export const expandedFilterGridContentScalingFunction =
  (leftColumnWidthWhenExpanded: number): ContentScalingFunction =>
  containerWidth =>
    ratio(containerWidth, leftColumnWidthWhenExpanded)
