import React, { PropsWithChildren, useRef } from 'react'
import classNames from 'classnames'
import useBreakpoints from '@/hooks/useBreakpoints'
import useIsSticky from '@/hooks/useIsSticky'
import { HEADER_HEIGHT, SECONDARY_NAV_HEIGHT_PX } from '@/constants'

interface Props extends PropsWithChildren {
  className?: string
}

const Header = ({ children, className = '' }: Props) => {
  const { isMobile } = useBreakpoints()
  const assetsHeaderRef = useRef<HTMLDivElement>(null)

  const headerHeight = isMobile ? HEADER_HEIGHT.MOBILE : HEADER_HEIGHT.WEB

  const isAssetsHeaderSticky = useIsSticky(assetsHeaderRef, {
    threshold: 0.999,
    rootMargin: `-${SECONDARY_NAV_HEIGHT_PX} 0px 0px 0px`
  })

  const stickyStyles = isAssetsHeaderSticky
    ? '!border-defaultBorder shadow-darkMenu !bg-black'
    : ''

  const classname = classNames(
    'sticky z-20 p-2 md:p-4 border-b-1 max-md:mb-[1px] bg-black md:bg-transparent border-transparent transition-colors min-w-0',
    stickyStyles,
    className
  )
  const style = { top: `${headerHeight - 1}px` }

  return (
    <div ref={assetsHeaderRef} className={classname} style={style}>
      {children}
    </div>
  )
}

export default Header
