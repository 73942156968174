import performLayout from './layout'
import { Size } from '@/types/grid'
import { Card, FilterOpenAction, State } from './'
import { DetailPresentationStyle } from './hooks/useDetailPresentationStyle'

// TODO: Make reducer use actions.

const reduce = <Details>(
  card: Card<Details> | 'inherit' | undefined,
  container: HTMLDivElement,
  contentSize: Size | 'inherit',
  staticElementHeight: number,
  wantsLeftColumnOpen: FilterOpenAction | 'inherit',
  isMobile: boolean,
  detailPresentationStyle: DetailPresentationStyle,
  state?: State<Details>
): State<Details> => {
  contentSize = contentSize !== 'inherit' ? contentSize : state.contentSize

  if (wantsLeftColumnOpen === 'inherit') {
    wantsLeftColumnOpen =
      typeof state?.wantsLeftColumnOpen !== 'undefined'
        ? state?.wantsLeftColumnOpen
          ? true // coerce an old `force` into true
          : false
        : true
  } else {
    wantsLeftColumnOpen = wantsLeftColumnOpen ? 'force' : false
  }

  let selectedItem = card == 'inherit' && state?.selectedItem

  if (card !== 'inherit') {
    selectedItem = {
      active: card,
      previous: state?.selectedItem?.active
    }
  }

  const nextLayout = performLayout(
    container,
    contentSize,
    staticElementHeight,
    detailPresentationStyle,
    wantsLeftColumnOpen,
    isMobile,
    selectedItem?.active?.index,
    state?.layout
  )

  // The layout could have forced the left column closed, so derive the state's
  // `wantsLeftColumnOpen` value from the layout's state to be used on subsequent
  // invocations.
  wantsLeftColumnOpen =
    nextLayout.filterPresentationStyle.style === 'left' &&
    nextLayout.filterPresentationStyle.mode === 'expanded'

  state = {
    contentSize: contentSize,
    wantsLeftColumnOpen: wantsLeftColumnOpen,
    layout: nextLayout,
    selectedItem: selectedItem
  }

  return state
}

export default reduce
