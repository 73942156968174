import React, { useEffect, useRef, useState, ReactNode } from 'react'
import { useRouter } from 'next/router'
import { Size } from '@/types/grid'
import useDetailPresentationStyle, {
  DetailPresentationStyle
} from './hooks/useDetailPresentationStyle'
import AbstractProductGrid, {
  Card as AbstractProductGridCard
} from '@/components/ProductGrid/AbstractProductGrid'
import useBreakpoints from '@/hooks/useBreakpoints'
import { CardMetrics } from '@/components/ProductFluidContainer'
import { Layout } from './layout'
import reduce from './reducer'
import InfiniteScroll from 'react-infinite-scroller'
import Loading from '@/components/Loading'
import Header from './header/Header'
import SearchAndSort from './header/SearchAndSort'
import InventoryFilterSummary from '@/components/InventoryFilterSummary'
import { SEARCH_PARAMS } from '@/constants/searchParams'
import {
  INVENTORY_SORT_OPTIONS,
  LISTING_SORT_OPTIONS,
  SortOption
} from '@/constants'
import Modal from '@/components/Modals/ModalV2/BaseModal'
import { CatalogItem } from '@/api/resources/catalog/details/get/schema'
import { Asset } from '@/api/resources/asset/get/schema'
import { DetailAssetType } from '@/components/ItemDetail'
import { TraitItem } from '@/api/resources/shared/trait'
import { findDropConfigById } from '@/config/drops'
import { classNames } from '@/util/tailwindHelpers'
import { CollectionItem } from '@/api/resources/shared/collection'
// import { visibleScrollOffset, absoluteY } from './layout/scrolling'

// Options Context
export type OptionsType = {
  owners?: string[]
  collections?: CollectionItem[]
  variants?: any
  rarities?: any
  traits?: TraitItem[]
}

export interface Card<Details>
  extends Omit<AbstractProductGridCard<Details>, 'content'> {
  id: number
  dropId: number
  index: number
  content: (
    isActive: boolean,
    layout: Layout,
    cardMetrics: CardMetrics
  ) => ReactNode
}

export type State<Details> = {
  contentSize: Size
  wantsLeftColumnOpen: FilterOpenAction
  layout: Layout
  selectedItem?: SelectedItem<Details>
}

export type SelectedItem<Details> = {
  active?: Card<Details>
  previous?: Card<Details>
}

export type FilterOpenAction = boolean | 'force'

interface Props<Details> {
  className?: string
  options: OptionsType
  detailAssetType?: DetailAssetType
  cards: Card<Details>[]
  sortOptions?: SortOption[]
  defaultSortOption?: SortOption
  details: (
    details: Details,
    presentationStyle: DetailPresentationStyle
  ) => ReactNode
  fetchNextPage: () => void
  hasNextPage: boolean
  hideSort?: boolean
  bodyTopSlot?: ReactNode
  renderTransferToolbar?: ReactNode
  renderFilter?: (onToggle: () => void, isExpanded: boolean) => ReactNode
  renderEmptyResults?: ReactNode
  headerClassName?: string
  // Represents the sticky height of the sticky elements that appear at the top of the viewport that would cover the grid.
  // This is used in determining a dynamic "zeroed y position" for the grid when adjusting the scroll offset for the selected element.
  staticElementHeight?: number
}

const InventoryBrowser = <Details extends CatalogItem | Asset>({
  className,
  options,
  cards,
  bodyTopSlot,
  renderEmptyResults,
  details,
  fetchNextPage,
  hasNextPage,
  hideSort,
  renderFilter,
  renderTransferToolbar,
  sortOptions,
  defaultSortOption,
  staticElementHeight = 0
}: Props<Details>) => {
  const { isMobile } = useBreakpoints(['mobile'])
  const container = useRef<HTMLDivElement>(undefined)
  const detailPresentationStyle = useDetailPresentationStyle()
  const router = useRouter()
  const { query } = router

  // 🚨 Important we only do one render when selectedItem/contentSize change
  //  thats why everything is communicated through the `state` property.
  const [state, setState] = useState<State<Details>>(undefined)
  let currentState = useRef<State<Details>>(undefined)

  // sort options can change based on route (/inventory vs /inventory/?show_only_listings=true)
  const sortDropDownOptions = sortOptions
    ? sortOptions
    : query.show_only_listings
    ? LISTING_SORT_OPTIONS
    : INVENTORY_SORT_OPTIONS

  const defaultSortDropDownOption = defaultSortOption
    ? defaultSortOption
    : query.show_only_listings
    ? LISTING_SORT_OPTIONS[0]
    : INVENTORY_SORT_OPTIONS[6]

  const close = () => {
    updateActiveCard(undefined)
  }

  const toggleLeftColumnAppearance = () => {
    const wantsLeftColumnOpen: FilterOpenAction =
      state.layout.filterPresentationStyle.style == 'left' &&
      state.layout.filterPresentationStyle.mode == 'collapsed'
        ? 'force'
        : !state.wantsLeftColumnOpen

    // @todo: How do we make this perform a dry run and we let closing
    // below invoke the actual state updating. I think we need to store
    // the user wants left column open preference in a useRef. The benefit
    // of this is we would then only do one render. Right now we are doing two.
    // Trevor - 05/03/2023
    const updatedState = update('inherit', 'inherit', wantsLeftColumnOpen)

    if (
      updatedState.wantsLeftColumnOpen &&
      state.layout.detailPresentationStyle === 'inline' &&
      updatedState.layout.detailPresentationStyle === undefined
    ) {
      // Close the right column if we are currently displaying a right column
      // but the new state says we should close it (as indicated by
      // detailPresentationStyle being undefined)
      close()
    }
  }

  // -- Internal --

  const updateActiveCard = (card?: Card<Details>) => {
    if (!card) {
      delete router.query[SEARCH_PARAMS.ITEM]
    }

    const nextRouter = !card
      ? router
      : {
          query: {
            ...router.query,
            item: `${card?.id}`
          }
        }

    router.replace(nextRouter, null, { scroll: false, shallow: true })
  }

  const update = (
    card: Card<Details> | 'inherit' | undefined,
    contentSize: Size | 'inherit',
    wantsLeftColumnOpen: FilterOpenAction | 'inherit',
    dryrun: boolean = false
  ): State<Details> => {
    const nextState = reduce(
      card,
      container.current,
      contentSize,
      staticElementHeight,
      wantsLeftColumnOpen,
      isMobile,
      detailPresentationStyle,
      currentState.current
    )

    // Scroll the container!
    // if (clicked) {
    //   scroll(container.current, nextState, currentState.current)
    // clicked = false
    // }

    if (dryrun) return nextState

    // Need to also store currentState in a ref incase the caller of update is within useEffect
    // that does not react to a state change. We need to know the latest value of state in all cases.
    currentState.current = nextState
    setState(nextState)

    return nextState
  }

  useEffect(() => {
    const index = cards.findIndex(
      item => item.id === Number(router.query[SEARCH_PARAMS.ITEM])
    )
    const card = cards[index]

    const observer = new ResizeObserver(entries => {
      update(card, entries[0].contentRect, 'inherit')
    })

    observer.observe(document.body)

    return () => {
      observer.unobserve(document.body)
    }
  }, [detailPresentationStyle, router.query[SEARCH_PARAMS.ITEM]])

  //   useEffect(() => {
  //     const scrollListener = () => {
  //       const layout = state?.layout
  //
  //       if (!layout) return
  //
  //       const containerVisibleScrollOffset = visibleScrollOffset(
  //         absoluteY(container.current, layout.scrollOffset),
  //         staticElementHeight,
  //         layout.scrollOffset
  //       )
  //
  //       console.log('containerVisibleScrollOffset', containerVisibleScrollOffset)
  //       console.log('layout.scrollOffset', layout.scrollOffset)
  //
  //       if (containerVisibleScrollOffset <= 0 && layout.scrollOffset > 0) {
  //         // In order to prevent a hitch when scrolling back to the top after selecting
  //         // the first item, we need to tell the window to scroll to 0, 73 (the visual zero
  //         // point because the nav bar is always sticky). We also need to set the marginTop
  //         // before the react render loop can get to it.
  //         forceOffset(container.current, 0, undefined) // staticElementHeight
  //
  //         // call update instead!
  //         // setLayout({
  //         //   ...layout,
  //         //   offset: 0
  //         // })
  //       }
  //     }
  //
  //     document.addEventListener('scroll', scrollListener)
  //
  //     return () => {
  //       document.removeEventListener('scroll', scrollListener)
  //     }
  //   }, [state?.layout])

  // const bannerState = query.show_only_listings
  //   ? WalletContextualBannerState.ReactivateListings
  //   : WalletContextualBannerState.ReviewInactive

  const render = (state?: State<Details>): JSX.Element => {
    if (!state || !state?.layout) return null

    const { layout, selectedItem } = state
    const { detailPresentationStyle } = layout

    return (
      <div
        className={classNames(
          'relative flex flex-1 min-w-0',
          // this makes sure the footer is below the fold, relies on having both header and secondary nav
          'min-h-[calc(100vh-calc(var(--headerHeight)+var(--secondaryNavHeight)))]',
          className
        )}
        style={{ '--cardWidth': '100px' }}
      >
        {!!renderFilter && layout.filterPresentationStyle.style === 'left' && (
          <div
            className="relative shrink-0 flex z-[21]"
            style={{
              width: `${layout.filterPresentationStyle.width}px`
            }}
          >
            {/* <div
                className="absolute top-[8px] right-[-24px] h-[32px] w-[24px] bg-white z-[10000] drop-shadow-md rounded-r-lg"
                onClick={() => {
                  toggleLeftColumnAppearance()
                }}
              ></div> */}
            {renderFilter(
              toggleLeftColumnAppearance,
              layout.filterPresentationStyle.mode === 'expanded'
            )}
          </div>
        )}

        <div className="flex-1 min-w-0 flex flex-col">
          {renderTransferToolbar && renderTransferToolbar}

          <Header>
            <SearchAndSort
              renderFilter={() => renderFilter(undefined, true)}
              hideSort={hideSort}
              sortOptions={sortDropDownOptions}
              defaultSortOption={defaultSortDropDownOption}
            />
            <InventoryFilterSummary options={options} />
          </Header>
          <div className="flex-1 md:px-4 px-2 pb-16">
            {bodyTopSlot}
            <div className="relative flex">
              {/* <div
                id="top-padding-container"
                className="bg-success"
                style={{ height: layout?.scrollOffset || 0 }}
              ></div> */}
              <div
                ref={container}
                id="layout-container"
                className="w-full flex flex-col gap-y-[16px]"
                // style={{
                //   transform: `translateY(${layout?.scrollOffset || 0}px)`
                // }}
              >
                {cards.length ? (
                  <InfiniteScroll
                    className="!overflow-visible"
                    loadMore={fetchNextPage}
                    hasMore={hasNextPage}
                    loader={
                      <div key="spinner" className="relative h-5 translate-y-5">
                        <Loading size="medium" />
                      </div>
                    }
                  >
                    <AbstractProductGrid
                      cards={() =>
                        cards.map(card => ({
                          ...card,
                          content: (_, cardMetrics) =>
                            card.content(
                              selectedItem?.active?.id == card.id,
                              layout,
                              cardMetrics
                            )
                        }))
                      }
                      layoutGenerator={{
                        type: 'precomputed',
                        ...layout
                      }}
                      className="w-full"
                    />
                  </InfiniteScroll>
                ) : (
                  renderEmptyResults
                )}
              </div>
            </div>
          </div>
        </div>

        {/* ITEM DETAIL */}

        {/* SHEET */}
        {!!selectedItem?.active &&
          ['sheet'].includes(detailPresentationStyle) && (
            <InventoryDetailSheet onClickOutside={close}>
              {details(selectedItem?.active?.details, detailPresentationStyle)}
            </InventoryDetailSheet>
          )}

        {/* MODAL */}
        {!!selectedItem?.active &&
          ['modal'].includes(detailPresentationStyle) && (
            <InventoryDetailModal
              close={close}
              selectedItem={selectedItem}
              details={details}
            />
          )}

        {/* INLINE */}
        {detailPresentationStyle === 'inline' && (
          <div
            className="shrink-0 flex"
            style={{
              width: layout.rightColumnWidth
            }}
          >
            {details(selectedItem?.active?.details, detailPresentationStyle)}
          </div>
        )}
      </div>
    )
  }

  return render(state)
}

export default InventoryBrowser

const InventoryDetailModal = ({ selectedItem, details, close }) => {
  const modalTheme = findDropConfigById(
    selectedItem?.active?.dropId || selectedItem?.active?.details?.drop_id
  )?.theme

  return (
    <Modal
      theme={modalTheme}
      overlayHeaderOpaqueOnScroll={true}
      title={{
        header: selectedItem?.active?.details.name,
        body: ''
      }}
      hide={close}
      isOpen={true}
    >
      {() => <div>{details(selectedItem?.active?.details, 'modal')}</div>}
    </Modal>
  )
}

// TEMP COMPONENT
export const InventoryDetailSheet = ({ children, onClickOutside }) => {
  useEffect(() => {
    document.body.classList.add('blockBodyScrolling')
    return () => {
      document.body.classList.remove('blockBodyScrolling')
    }
  }, [])

  return (
    <div
      className="fixed left-0 w-screen h-full z-[200] flex justify-end"
      style={{ top: 'var(--headerHeight)' }}
    >
      <button
        className="flex-1 bg-gradient-to-l from-black/97 to-black/75"
        onClick={onClickOutside}
      />
      <div className="flex flex-col bg-black w-[400px]">{children}</div>
    </div>
  )
}
