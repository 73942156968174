import { Size } from '@/types/grid'
import { Layout as GridLayout } from './grid'
import { DetailPresentationStyle } from '@/components/InventoryBrowser/hooks/useDetailPresentationStyle'
import { RIGHT_COLUMN_WIDTH, LEFT_COLUMN_EXPANDED_WIDTH } from './constants'
import { GRID_X_PADDING } from '@/components/ProductGrid'
import {
  PRODUCT_CARD_MAX_SIZE,
  PRODUCT_CARD_MIN_WIDTH,
  PRODUCT_CARD_MIN_WIDTH_MOBILE
} from '@/components/ProductCard'
import {
  FilterPresentationStyle,
  inlinePresentationStyle,
  leftPresentionStyle,
  leftPresentationStyleMode
} from './filterPresentationStyle'
import {
  rightColumnGridContentScalingFunction,
  expandedFilterGridContentScalingFunction
} from './scaling'
import { gridWithAutomaticGap } from './grid'
import scrollOffset from './scrolling'

export type Layout = {
  filterPresentationStyle: FilterPresentationStyle
  detailPresentationStyle: DetailPresentationStyle
  rightColumnWidth: number
  scrollOffset: number
} & GridLayout

const performLayout = (
  container: HTMLDivElement,
  contentSize: Size,
  staticElementHeight: number,
  detailPresentationStyle: DetailPresentationStyle,
  wantsLeftColumnOpen: boolean | 'force',
  isMobile: boolean,
  selectedItemIndex?: number,
  currentLayout?: Layout
): Layout => {
  const hasSelectedItem = typeof selectedItemIndex !== 'undefined'

  let rightColumn =
    hasSelectedItem && detailPresentationStyle === 'inline'
      ? RIGHT_COLUMN_WIDTH
      : undefined

  let filterPresentationStyle: FilterPresentationStyle = isMobile
    ? inlinePresentationStyle()
    : leftPresentionStyle(wantsLeftColumnOpen ? 'expanded' : 'collapsed')

  let leftColumn =
    filterPresentationStyle.style === 'left'
      ? filterPresentationStyle.width
      : undefined

  const maximumCardSize = PRODUCT_CARD_MAX_SIZE
  const productCardMinWidth = isMobile
    ? PRODUCT_CARD_MIN_WIDTH_MOBILE
    : PRODUCT_CARD_MIN_WIDTH
  const containerPadding = isMobile
    ? GRID_X_PADDING.mobile
    : GRID_X_PADDING.default

  // Do an initial pass of the grid's layout
  let gridLayout = gridWithAutomaticGap(
    contentSize,
    leftColumn,
    rightColumnGridContentScalingFunction(
      detailPresentationStyle,
      RIGHT_COLUMN_WIDTH,
      !!rightColumn
    ),
    maximumCardSize,
    productCardMinWidth,
    containerPadding,
    isMobile
  )

  const offset = scrollOffset(
    container,
    staticElementHeight,
    gridLayout,
    selectedItemIndex,
    currentLayout
  )

  // Don't allow the right and left columns to both appear at the same time if the columns are less than 3.
  if (gridLayout.columns < 3 && filterPresentationStyle.style === 'left') {
    // Expand the filter bar if it is forced open (via the toggle) or if the left column is wishing to be open
    // and there isn't currently an active item showing in the right column. The `detailPresentationStyle === 'inline'`
    // is important because we do not want to force it open if a right column doesn't exist, but an active item is being
    // displayed modally.
    filterPresentationStyle = leftPresentionStyle(
      leftPresentationStyleMode(
        wantsLeftColumnOpen,
        detailPresentationStyle,
        hasSelectedItem,
        rightColumn
      )
    )

    leftColumn = filterPresentationStyle.width
    rightColumn = wantsLeftColumnOpen === 'force' ? undefined : rightColumn
    detailPresentationStyle =
      wantsLeftColumnOpen === 'force' ? undefined : detailPresentationStyle

    if (filterPresentationStyle.mode === 'expanded') {
      // Simulate a right column scale factor when the left column is expanded.
      gridLayout = gridWithAutomaticGap(
        contentSize,
        0,
        expandedFilterGridContentScalingFunction(LEFT_COLUMN_EXPANDED_WIDTH),
        maximumCardSize,
        productCardMinWidth,
        containerPadding,
        isMobile
      )
    } else {
      gridLayout = gridWithAutomaticGap(
        contentSize,
        leftColumn,
        rightColumnGridContentScalingFunction(
          detailPresentationStyle,
          RIGHT_COLUMN_WIDTH,
          !!rightColumn
        ),
        maximumCardSize,
        productCardMinWidth,
        containerPadding,
        isMobile
      )
    }
  }

  detailPresentationStyle = hasSelectedItem
    ? detailPresentationStyle
    : undefined

  return {
    filterPresentationStyle: filterPresentationStyle,
    detailPresentationStyle: detailPresentationStyle,
    rightColumnWidth: rightColumn,
    scrollOffset: offset,
    ...gridLayout
  }
}

export default performLayout
