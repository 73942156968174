import classnames from 'classnames'
import React from 'react'
import useBreakpoints from '@/hooks/useBreakpoints'
import Icon from '@/components/Icon'
import { CircleIconButton } from '@/components/CircleIconLink'
import styles from './styles.module.scss'
import NotificationBadge from '@/components/NotificationBadge'

interface Props {
  isExpanded: boolean
  onClick?: () => void
  value?: number
}

const ToggleFilterButton = ({ onClick, isExpanded, value }: Props) => {
  const { isMobile } = useBreakpoints()
  const collapsed = !isMobile && !isExpanded

  if (isMobile) {
    return (
      <CircleIconButton
        className="ml-2 relative"
        size="sm"
        name="filter3"
        onClick={onClick}
      >
        {value > 0 && (
          <NotificationBadge
            value={value}
            className="absolute -top-half -right-half"
          />
        )}
      </CircleIconButton>
    )
  }

  return (
    <div className={styles.container} onClick={onClick}>
      <Icon
        className={classnames({
          'mr-[3px]': !collapsed,
          'rotate-180': collapsed
        })}
        name="collapse"
      />
    </div>
  )
}

export default ToggleFilterButton
