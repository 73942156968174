import { Size } from '@/types/grid'
import { Layout } from './'
import { Layout as GridLayout } from './grid'
import { MAXIMUM_CARD_DIMENSIONS } from './constants'

// Returns the absolute Y position of an element.
export const absoluteY = (element: HTMLDivElement, offset: number): number => {
  if (!element) return 0
  // return 139
  return element.getBoundingClientRect().y + window.scrollY - offset
}

const size = (width: number, maximum: Size): Size => {
  return {
    width: width,
    height: (width / maximum.width) * maximum.height
  }
}

// containerAbsoluteY: The absolute y (non-scrolled) position of the container from the top of the body.
// visibleYPoint: The y position from the top where the container's items first appear. This value is used when
// there are any fixed/static elements such as nav bars that appear over the body.
// offset: Any offset currently applied above the container's contents.
export const visibleScrollOffset = (
  containerAbsoluteY: number,
  visibleYPoint: number,
  offset: number = 0
): number => {
  const visibleIntersectionPoint = containerAbsoluteY - visibleYPoint + offset
  const visibleScrollOffset = window.scrollY - visibleIntersectionPoint
  return visibleScrollOffset
}

const itemPosition = (
  index: number,
  cardSize: Size,
  columns: number,
  gap: number
): number => {
  const rowOfElement = Math.floor(index / columns)
  return cardSize.height * rowOfElement + gap
}

const scrollOffset = (
  container: HTMLDivElement,
  visibleYPoint: number,
  gridLayout: GridLayout,
  selectedItemIndex?: number,
  currentLayout?: Layout
): number => {
  if (!selectedItemIndex) {
    return 0
  }

  const containerAbsoluteY = absoluteY(
    container,
    currentLayout?.scrollOffset || 0
  )

  const unscaledCardSize = size(
    gridLayout.unscaledColumnWidth,
    MAXIMUM_CARD_DIMENSIONS
  )

  const scaledCardSize = size(gridLayout.columnWidth, MAXIMUM_CARD_DIMENSIONS)

  // There is an active selected item, it's either new (we are transitioning from closed to open)
  // or there was a previously selected item. Either way we need to adjust the offset to account
  // for both scenarios.

  const normalElementPosition =
    itemPosition(
      selectedItemIndex,
      unscaledCardSize,
      gridLayout.columns,
      gridLayout.gap
    ) + containerAbsoluteY

  // Get the element's position in the new scaled layout.
  const scaledElementPosition =
    itemPosition(
      selectedItemIndex,
      scaledCardSize,
      gridLayout.columns,
      gridLayout.gap
    ) + containerAbsoluteY

  const offset = normalElementPosition - scaledElementPosition

  const containerVisibleScrollOffset = visibleScrollOffset(
    containerAbsoluteY,
    visibleYPoint,
    currentLayout?.scrollOffset
  )

  // We are too close to the top of container. Skip returning an offset because if we do, the user
  // will see the offset introduced at the top.
  if (containerVisibleScrollOffset < 0) {
    return 0
  }

  // This is a newly selected item, return the offset!
  return offset
}

export default scrollOffset
