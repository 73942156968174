import { useWindowWidth } from '@/contexts/windowDimensions'
import useBreakpoints from '@/hooks/useBreakpoints'

export type DetailPresentationStyle = 'inline' | 'modal' | 'sheet' | undefined

// Sheet window width is an arbitrary value that we picked based on what looks best
// with the default PRODUCT_CARD_MIN_WIDTH being 200. This may have to change if
// that value changes.
const sheetWindowWidth = 1036

const useDetailPresentationStyle = (
  sheetThreshold: number = sheetWindowWidth
): DetailPresentationStyle => {
  const { isMobile } = useBreakpoints()
  const windowWidth = useWindowWidth()

  if (isMobile) {
    return 'modal'
  } else if (windowWidth <= sheetThreshold) {
    return 'sheet'
  }

  return 'inline'
}

export default useDetailPresentationStyle
