import { Size } from '@/types/grid'
import { Layout as ProductGridLayout, GRID_GAP } from '@/components/ProductGrid'
import { PRODUCT_CARD_SMALL_LAYOUT_THRESHOLD } from '@/components/ProductCard'

export type Layout = {
  unscaledColumnWidth: number
} & ProductGridLayout

// `minimum` indicates that the content scaling function wants the
// "worst case" (smallest) scale that could be applied.
// `current` indicates that the content scaling function wants the
// current scale that should be applied to the grid's items when
// determining the grid's layout.
export type ScalingMode = 'minimum' | 'current'

// A function that gives the caller of `grid(...)` the opportunity to
// compute a scale to be applied based on the caller's own internal
// state.
export type ContentScalingFunction = (
  containerWidth: number,
  mode: ScalingMode
) => number

export const gridWithAutomaticGap = (
  contentSize: Size,
  leftColumn: number = 0,
  scale: ContentScalingFunction,
  maximumCardSize: Size,
  minimumCardWidth: number,
  containerPadding: number,
  isMobile: boolean
): Layout => {
  const gap = isMobile ? GRID_GAP.small : GRID_GAP.regular

  let layout = grid(
    contentSize,
    leftColumn,
    scale,
    maximumCardSize,
    minimumCardWidth,
    containerPadding,
    gap
  )

  if (!isMobile && layout.columnWidth < PRODUCT_CARD_SMALL_LAYOUT_THRESHOLD) {
    const candidateLayout = grid(
      contentSize,
      leftColumn,
      scale,
      maximumCardSize,
      minimumCardWidth,
      containerPadding,
      GRID_GAP.small
    )

    if (candidateLayout.columnWidth < PRODUCT_CARD_SMALL_LAYOUT_THRESHOLD) {
      layout = candidateLayout
    }
  }

  return layout
}

const grid = (
  contentSize: Size,
  leftColumn: number = 0,
  scale: ContentScalingFunction,
  maximumCardSize: Size,
  minimumCardWidth: number,
  containerPadding: number,
  gap: number
): Layout => {
  const containerWidth = contentSize.width - leftColumn - containerPadding * 2
  const currentScale = scale(containerWidth, 'current')
  const minimumScale = scale(containerWidth, 'minimum')

  for (let i = 1; i > 0; i++) {
    const gaps = (i - 1) * gap
    const columnWidth = (containerWidth - gaps) / i
    const scaledColumnWidth = (containerWidth * currentScale - gaps) / i

    // Using the `minimumScale` determine what the next scaled card would be if
    // scaling was applied.
    const nextGaps = i * gap
    const nextScaledColumnWidth =
      (containerWidth * minimumScale - nextGaps) / (i + 1)

    const layout = {
      columns: i,
      columnWidth: scaledColumnWidth,
      unscaledColumnWidth: columnWidth,
      gap: gap
    }

    // If the next scaled column width is smaller than the minimumCardWidth then return
    // the current layout.
    if (nextScaledColumnWidth < minimumCardWidth) {
      return layout
    }

    // If the columnWidth is greater than the the maximum allowed card width, then continue the loop
    // and introduce another column.
    if (columnWidth > maximumCardSize.width) {
      continue
    }

    return layout
  }
}

export default grid
