import {
  LEFT_COLUMN_EXPANDED_WIDTH,
  LEFT_COLUMN_COLLAPSED_WIDTH
} from './constants'
import { DetailPresentationStyle } from '@/components/InventoryBrowser/hooks/useDetailPresentationStyle'

export type FilterPresentationStyle =
  | LeftPresentationStyle
  | InlinePresentationStyle

export type LeftPresentationStyle = {
  style: 'left'
  mode: LeftPresentationStyleMode
  width: number
}

export type LeftPresentationStyleMode = 'expanded' | 'collapsed'

export type InlinePresentationStyle = {
  style: 'inline'
}

export const inlinePresentationStyle = (): InlinePresentationStyle => {
  return { style: 'inline' }
}

export const leftPresentionStyle = (
  mode: 'expanded' | 'collapsed'
): LeftPresentationStyle => {
  return {
    style: 'left',
    mode: mode,
    width:
      mode == 'expanded'
        ? LEFT_COLUMN_EXPANDED_WIDTH
        : LEFT_COLUMN_COLLAPSED_WIDTH
  }
}

export const leftPresentationStyleMode = (
  wantsLeftColumnOpen: boolean | 'force',
  detailPresentationStyle: DetailPresentationStyle,
  showingActiveItem: boolean,
  rightColumn?: number
): LeftPresentationStyleMode => {
  if (wantsLeftColumnOpen === 'force') {
    return 'expanded'
  }

  if (wantsLeftColumnOpen) {
    if (
      detailPresentationStyle === 'inline' &&
      (!rightColumn || !showingActiveItem)
    ) {
      return 'expanded'
    }

    if (detailPresentationStyle === 'sheet' && showingActiveItem) {
      return 'expanded'
    }

    if (!showingActiveItem) {
      return 'expanded'
    }
  }

  return 'collapsed'
}
