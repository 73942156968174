import classnames from 'classnames'
import { useRouter } from 'next/router'
import React, { ReactNode, useEffect, useState } from 'react'
import { useResizeDetector } from 'react-resize-detector'
import { InputSearch } from '@/components/Inputs'
import { INVENTORY_SORT_OPTIONS, SortOption } from '@/constants'
import useBreakpoints from '@/hooks/useBreakpoints'
import ConditionalWrap from '@/components/ConditionalWrap'
import SortFilterDropdown from '@/components/SortFilterDropdown'
import ToggleFilterButton from '@/components/ToggleFilterButton'
import BooleanModal from '@/components/Modals/ModalV2/BooleanModal'
import { useModal } from '@ebay/nice-modal-react'
import { getSearchParamLength } from '@/util/queryHelpers'
import { useSearchPlaceHolderMessage } from '@/hooks/useSearchPlaceholder'

export interface Props {
  hideSort?: boolean
  renderFilter: () => ReactNode
  defaultSortOption: SortOption
  sortOptions?: SortOption[]
}

const SearchAndSort = ({
  hideSort,
  renderFilter,
  defaultSortOption = INVENTORY_SORT_OPTIONS[0],
  sortOptions = INVENTORY_SORT_OPTIONS
}: Props) => {
  const [open, setOpen] = useState(false)
  const router = useRouter()
  const { isMobile } = useBreakpoints(['mobile'])
  const { ref } = useResizeDetector()
  const MobileFilterModal = useModal(BooleanModal)

  // Hide the filter modal when the screen size is no longer mobile
  useEffect(() => {
    if (!isMobile && MobileFilterModal.visible) {
      MobileFilterModal.hide()
    }
  }, [isMobile])

  const handleReset = () => {
    delete router.query.search
    delete router.query.owners
    delete router.query.collections
    delete router.query.rarities
    delete router.query.variants
    delete router.query.traits
    router.push(router, null, { scroll: false, shallow: true })
    MobileFilterModal.hide()
  }

  const { owners, collections, rarities, variants, traits, search, sort } =
    router.query

  const filterLength = getSearchParamLength([
    owners,
    collections,
    rarities,
    variants,
    traits
  ])

  const onOpenFilterModal = () => {
    MobileFilterModal.show({
      shouldPersistOnRouteChange: true,
      children: renderFilter(),
      secondaryButton: {
        theme: 'secondary',
        label: 'Reset',
        onClick: handleReset
      },
      primaryButton: {
        label: 'Continue',
        onClick: MobileFilterModal.hide
      },
      title: 'Filter',
      renderInlineTitle: false
    })
  }

  const handleChangeSearch = search => {
    router.query.search = search
    router.push(router, null, { scroll: false, shallow: true })
  }

  const handleChangeSort = sort => {
    router.query.sort = sort
    router.push(router, null, { scroll: false, shallow: true })
  }

  return (
    <div ref={ref}>
      <div className="flex items-center">
        <InputSearch
          fullWidth
          value={search?.toString()}
          placeholder={useSearchPlaceHolderMessage()}
          onChange={handleChangeSearch}
        />

        {isMobile && (
          <ToggleFilterButton
            isExpanded={open}
            onClick={onOpenFilterModal}
            value={filterLength}
          />
        )}

        {!hideSort && (
          <ConditionalWrap
            condition={!isMobile}
            wrap={children => (
              <div
                className={classnames('relative overflow-hidden', {
                  'overflow-visible': open
                })}
              >
                {children}
              </div>
            )}
          >
            <SortFilterDropdown
              defaultOption={defaultSortOption}
              value={sort}
              options={sortOptions}
              onChange={handleChangeSort}
              onTrigger={value => setOpen(!!value)}
            />
          </ConditionalWrap>
        )}
      </div>
    </div>
  )
}

export default SearchAndSort
