import {
  INVENTORY_SEARCH_PLACEHOLDER_MESSAGE,
  INVENTORY_SEARCH_PLACEHOLDER_MESSAGE_SHORT
} from '@/constants/search'
import useBreakpoints from './useBreakpoints'

export const useSearchPlaceHolderMessage = (): string => {
  const { isSmall } = useBreakpoints()
  return isSmall
    ? INVENTORY_SEARCH_PLACEHOLDER_MESSAGE
    : INVENTORY_SEARCH_PLACEHOLDER_MESSAGE_SHORT
}
